__webpack_public_path__ = "http://dev.local:8080/assets/";

import "../scss/application.scss";
import {onLoading} from "./_modules/website";
import {loadPage} from "./_modules/home";
import 'lazysizes';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';


import './_modules/components/slideshow';
import './_modules/components/index-table';
import './_modules/components/fullpage';
import './_modules/components/collapsible';

document.addEventListener("DOMContentLoaded", () => {
    onLoading();
    loadPage();
});