const indexTableRow = document.querySelectorAll('.index-table--row a');

const coverContainer = document.querySelector('.index-table--cover-container');

indexTableRow.forEach((row) => {

    row.addEventListener('mouseenter', () =>{
        const cover = row.dataset.cover;
        coverContainer.innerHTML = cover;
    });
   /* row.addEventListener('mouseleave', () =>{
        coverContainer.innerHTML = null;
    });*/
});