require('flickity-imagesloaded');
require('flickity-fullscreen');

import Flickity from 'flickity';
import 'flickity/css/flickity.css';
import TouchSweep from "touchsweep";


const slideshows = document.querySelectorAll(".carousel");

var autoplay

if(document.body.dataset.template == "home"){
     autoplay = 2000;
}else{
     autoplay = false;
}

slideshows.forEach((elem) => {
    var flkty = new Flickity(elem, {
        cellAlign: 'center',
        contain: true,
        lazyLoad: 2,
        autoPlay: autoplay,
        selectedAttraction: 1,
        friction: 1,
        wrapAround: true,
        pageDots: false,
        arrowShape: 'M57.99 74.41L32.81 49.23L57.99 24.04L58.88 24.92L34.58 49.23L58.88 73.53L57.99 74.41z',
        on: { ready: function() {
                const area = this.element;
                const data = {
                    value: 1
                };
                const touchThreshold = 20;
                const touchSweepInstance = new TouchSweep(area, data, touchThreshold);

                console.log(touchSweepInstance);

                area.addEventListener('swipeleft', event => {
                    event.preventDefault();
                    flkty.next(true);
                    flkty.stopPlayer();
                });
                area.addEventListener('swiperight', event => {
                    event.preventDefault();
                    flkty.previous(true);
                    flkty.stopPlayer();
                });
                area.addEventListener('swipeup', event => {
                    event.preventDefault();
                    flkty.next(true);
                    flkty.stopPlayer();
                });
                area.addEventListener('swipedown', event => {
                    event.preventDefault();
                    flkty.previous(true);
                    flkty.stopPlayer();
                });
            }
        }
    });

    function updateLegend() {
        const legendContainer = document.querySelector(".slideshow-legend");
        const activeSlide = flkty.selectedCell.element;
        const slideLegend = activeSlide?.dataset.legend;
        const hasbkg = activeSlide.hasAttribute('has-background') ? "has-background" : null;
        if (legendContainer) {
            if (hasbkg) {
                legendContainer.classList.add(hasbkg);
            } else {
                legendContainer.classList.remove('has-background');
            }
            legendContainer.innerHTML = slideLegend;
        }
    }

    flkty.on('lazyLoad', updateLegend);
    flkty.on('change', updateLegend);
    flkty.on( 'pointerDown', function( event, pointer ) {
        this.stopPlayer();
    });
});
