var coll = document.getElementsByClassName("collapsible");
var i;

for (i = 0; i < coll.length; i++) {

    coll[i].addEventListener("click", function() {
        console.log('collapse');
        this.classList.toggle("active");
        var content = this.parentElement.parentElement.querySelector('.content');
        if (content.style.maxHeight){
            content.style.maxHeight = null;
        } else {
            content.style.maxHeight = content.scrollHeight + "px";
        }
    });
}

