import fullpage from 'fullpage.js';
import 'fullpage.js/dist/fullpage.css';


const fullpageContainer = document.querySelector('#fullpage');

if (fullpageContainer) {
    var fullPageInstance = new fullpage('#fullpage', {
        sectionsColor: ['#f3000b', 'white'],
        licenseKey: 'gplv3-license',
        scrollingSpeed: 400,
        scrollBar: false,
        scrollOverflow: true,
        onLeave: function (origin, destination, direction, trigger) {
            var leavingSection = this;
            console.log(origin.index);
            const bioLink = document.querySelector('.sub-menu li a[href="#bio"]');
            console.log(bioLink);
            //after leaving section 2
            if (origin.index == 0 && direction == 'down') {
                bioLink.classList.add('active');
            } else {
                bioLink.classList.remove('active');
            }
        }

    });
}

const anchorLinks = document.querySelectorAll(".sub-menu li a");
const scrollContainer = document.querySelector(".anchors-section .fp-overflow") ?? document.querySelector("main");

anchorLinks.forEach((link) => {

    link.addEventListener('click', function (e) {
        e.preventDefault();
        anchorLinks.forEach((link) => {
            link.classList.remove('active');
        });
        link.classList.add('active');
        if(fullpageContainer){
            fullpage_api.moveTo(2, 0);
        }
        const href = link.getAttribute("href");
        const offsetTop = document.querySelector(href).offsetTop;
        scrollContainer.classList.add('is-moving');
        setTimeout(function () {
            smoothScrollTo(scrollContainer, offsetTop, 700);
        }, 200);

        setTimeout(function (){
            scrollContainer.classList.remove('is-moving');
        }, 900)
    });

});

function setActiveSection(){
    const pressSection = document.querySelector('#press');
    if(pressSection){
        if(pressSection.offsetTop - scrollContainer.scrollTop < 250){
            document.querySelector('.sub-menu li a[href="#press"]').classList.add('active');
            document.querySelector('.sub-menu li a[href="#bio"]').classList.remove('active');
        }else{
            document.querySelector('.sub-menu li a[href="#press"]').classList.remove('active');
            document.querySelector('.sub-menu li a[href="#bio"]').classList.add('active');

        }
    }
}

scrollContainer?.addEventListener('scroll', setActiveSection);


function smoothScrollTo(element, target, duration) {
    const start = element.scrollTop;
    const change = target - start;
    const startTime = performance.now();

    function animateScroll(currentTime) {
        const elapsedTime = currentTime - startTime;
        const progress = Math.min(elapsedTime / duration, 1);
        const easedProgress = easeInOutQuint(progress);

        element.scrollTop = start + change * easedProgress;

        if (elapsedTime < duration) {
            requestAnimationFrame(animateScroll);
        }
    }

    requestAnimationFrame(animateScroll);
}

function easeInOutQuad(t) {
    return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
}

function easeInOutCubic(t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
}

function easeInOutQuart(t) {
    return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
}

function easeInOutQuint(t) {
    return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
}

function easeInOutSine(t) {
    return -(Math.cos(Math.PI * t) - 1) / 2;
}